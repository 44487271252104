import { OwAppTile } from '../interfaces/domain/ow-app-tile.modal';
import { AppDownload } from '../interfaces/domain/ow-app.modal';
// import { kdlListUITest } from './constants';
import { LocalizationService } from './localization-service';
import Utils from '../utils/utils';

// -----------------------------------------------------------------------------
export class DownloadUtils {
  private readonly config: any;

  constructor(config: any) {
    this.config = config;
  }

  // ---------------------------------------------------------------------------
  public createAppDownloadLinks(app: OwAppTile): void {
    if (!app) {
      return;
    }

    if (!app?.alternativeDownloads || app?.alternativeDownloads.length === 0) {
      app.alternativeDownloads = [];

      const fallBackDownloadItem: AppDownload = {
        label: LocalizationService.Translate('downloadApp'),
        platform: 'Windows',
        appId: app.id,
      };

      app.alternativeDownloads.push(fallBackDownloadItem);
    }

    app?.alternativeDownloads.forEach(item => {
      this.constructDownloadUrl(app, item);
    });
  }

  // ---------------------------------------------------------------------------
  private constructDownloadUrl(app: OwAppTile, item: AppDownload): void {
    if (item.url) {
      return;
    }

    const downloadBaseUrl =
      this.config?.publicRuntimeConfig?.base?.downloadBaseUrl;

    const downloadUrlPath = '/install/Download';
    const url = new URL(`${downloadBaseUrl}${downloadUrlPath}`);

    if (app.title) {
      url.searchParams.append('Name', Utils.sanitizeString(app.title));
    }

    if (item.appId) {
      url.searchParams.append('ExtensionId', Utils.sanitizeString(item.appId));
    } else {
      url.searchParams.append('ExtensionId', Utils.sanitizeString(app.id));
    }

    url.searchParams.append('Channel', 'web_dl_btn');
    item.url = url.toString();
  }
}
